import 'react-app-polyfill/ie11';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate, render} from 'react-dom';

const renderer = process.env.BUILD_TARGET === 'server' ? hydrate : render;

// console.log(process.env.BUILD_TYPE);

renderer(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
