import React, {useState} from 'react';
import { Route, Switch, NavLink, withRouter } from 'react-router-dom';

import Home from './Home';
import './markdown_styles.css';
import './App.css';
import './style.scss';

import ScrollDir from './lib/scrolldir';
import ScrollPos from './lib/scrollpos';
import imgLogo from './img/dw_logo.svg';
import menuItems from './menuItems.json';


const MenuItem = ({to, children, native, wrapper, ...item}) => {
  const Wrap = wrapper || null;

  const link = (
    (native ? (
      <a href={to} {...item}>{children}</a>
    ) : (
      <NavLink to={to} key={i} {...item}>{children}</NavLink>
    ))
  );

  return Wrap ? <Wrap>{link}</Wrap> : link;
};



class App extends React.Component {
  constructor(props) {
    super(props);
    const app = this;
    this._mounted = false;

    this.state = {
      stickyMenu: false,
      hiddenMenu: false,
      showMenu: false,
      scrollDir: 'down'
    };


    this.scrollPos = new ScrollPos((prevPos, curPos) => {
      if (!this._mounted) { return; }

      const newState = {
        stickyMenu: curPos.y > 0,
        hiddenMenu: this.state.hiddenMenu
      };

      if (prevPos.y > curPos.y) {
        newState.hiddenMenu = false;
      }
      else if (curPos.y > 10) {
        newState.hiddenMenu = true;
      }

      if (this.state.stickyMenu !== newState.stickyMenu ||
        this.state.hiddenMenu !== newState.hiddenMenu
      ) {
        this.setState(newState);
      }
    });

    this.scrollDir = new ScrollDir({
      attribute: false,
      el: '#root',
      onChange: (dir) => {
        if (!this._mounted) { return; }
        void this.setState({ scrollDir: dir });
      }
    });

    React.Component.prototype.getApp = function() { return app; };

    this.toggleMenu = () => this.setState({ showMenu: !this.state.showMenu })
  }

  scrollTop() {
    scrollToComponent(document.documentElement, {
      offset: 0,
      align: 'top',
      duration: 400,
      ease: 'inCirc'
    });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidMount() {
    this._mounted = true;
    this.props.history.listen((location) => {
      void this.setState({ showMenu: false });
    });
  }

  render() {
    return (
      <div className={`app page-wrapper scroll-dir-${this.state.scrollDir} mobilmenu-${this.state.showMenu ? 'on' : 'off'}${this.state.stickyMenu ? ' sticky-menu' : ''}${this.state.hiddenMenu ? ' hide-menu' : ''}`}>
        <header className="page-header">
          <div className="page-width clearfix">
            <div className="header-inner">
              <h1 className="site-name" style={{display: "none"}}>{process.env.RAZZLE_INITIAL_PAGE_TITLE}</h1>
              <a href={`/`} className="logo-wrp">
                <img src={imgLogo} alt="Digitalwave logo" width="150" height="auto" />
              </a>
              <strong className="project-name">ModSecurity</strong>
              <nav className="site-menu">
                <h1 className="offscreen">Main menu</h1>
                {menuItems.map((item, i) => <MenuItem key={i} {...item} />)}
              </nav>
              <button
                aria-label={"Main menu"}
                onClick={this.toggleMenu}
                className={`toggle-mobil-menu${this.state.showMenu ? ' active' : ''}`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
                </svg>
              </button>
            </div>
          </div>
          {this.state.showMenu &&
          <nav className="mobil-menu">
            <button className="mobil-menu__close" onClick={this.toggleMenu}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path fill="white" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                <path d="M0 0h24v24H0z" fill="none"/>
              </svg>
            </button>
            <div className="mobil-menu__items">
              {menuItems.map((item, x) => <MenuItem wrapper="span" key={x} {...item} />)}
            </div>
              <div className="mobil-menu__footer">
                <div className="c-left">
                  <div className="label">write to:</div>
                  <a target="_blank" href="mailto:hello@digitalwave.hu">hello@digitalwave.hu</a>
                </div>
                <div className="c-right">
                </div>
            </div>
          </nav>}
        </header>
        <Switch>
          {/* <Route exact path="/" component={Home} /> */}
          <Route component={Home} />
        </Switch>
        <footer className="page-footer">
          <div className="page-width clearfix">
            <hr />
            <nav className="footer-menu">
              <div className="links links--left">
              </div>
              <div className="links links--right">
              </div>
            </nav>
          </div>
        </footer>
      </div>
    );
  }
}


export default withRouter(App);
