import React from 'react';

import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {vscDarkPlus as hstyle} from 'react-syntax-highlighter/dist/esm/styles/prism'
import mdHomde from '!!raw-loader!./md-pages/index.md';
import gfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import  {isExternal} from './lib/util';

// https://github.com/remarkjs/react-markdown#readme
const renderers = {
  code: ({language, value}) => {
    return <SyntaxHighlighter style={hstyle} language={language} children={value} />
  }
}

const mdLinkTarget = (url, {type, value}) => {
  return isExternal(url) ? '_blank' : '';
};

class Home extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mdContent: mdHomde
    };
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidMount() {
      // import('!!raw-loader!./md-pages/index.md').then((module) => {
      //   this.setState({
      //     mdContent: module.default
      //   });
      // });
    this._mounted = true;
  }
  render() {
    return (
      <div className="page-content page-width">
        <div className="markdown-out">
          <ReactMarkdown
            linkTarget={mdLinkTarget}
            renderers={renderers}
            plugins={[gfm]}
            children={this.state.mdContent}
          />
        </div>
      </div>
    );
  }
}

export default Home;
