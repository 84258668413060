const pathNormalizer = {
  rx: {
    relative_cdp: /^\.\.?\//,
    relative_cd: /^\.\//,
    media_extension: /(?:\.)jpe?g|png|gif|svg|webp|heif|heic(\?.+)?$/i,
    external_src: /^(http)s?:/ig,
    publicpath: /^%PUBLIC_CONTENT%\/?/i,
    staticpath: /^%STATIC_CONTENT%\/?/i,
    anypath: /^%(PUBLIC_CONTENT|STATIC_CONTENT)%\/?/gim,
    // example: /static/media/eon-csr__card.eaa2408d.jpg
    replaceCRAwebpackSuffixFormResolved: (
      /\.[a-zA-Z0-9]+\.(jpe?g|png|gif|svg|webp|heif|heic)$/i
    )
  },
  isRelativeCdp(path) {
    return (path || '').search(this.rx.relative_cdp) > -1;
  },
  extractMediaExt(path) {
    return ((path || '').match(this.rx.media_extension) || [null])[0];
  },
  isExternalPath(path) {
    return (path || '').search(this.rx.external_src) > -1;
  },
  publicPath(path) {
    return this.isExternalPath(path) ? path : `%PUBLIC_CONTENT%/${this.cleanPath(path)}`;
  },
  staticPath(path) {
    return this.isExternalPath(path) ? path : `%STATIC_CONTENT%/${this.cleanPath(path)}`;
  },
  cleanPath(path) {
    return this.isExternalPath(path) ? path : path.replace(this.rx.anypath, '');
  },
  cleanWebpackSuffix(path) {
    path.replace(this.rx.replaceCRAwebpackSuffixFormResolved, '.$1');
  }
};

const getGlobal = () => {
  return (window || global || {});
};

const el_trigger = function el_trigger(el, event, data, bubbles = true, cancelable = false) {
  var eventNames = event.trim().split(/\s+/);
  if (!el || !el.dispatchEvent) {
    return;
  }
  for (var i = 0; i < eventNames.length; i++) {
    el.dispatchEvent(
      new window.CustomEvent(eventNames[i], {
        bubbles: !!bubbles,
        cancelable: !!cancelable,
        detail: { customEvent: true, customData: data || null }
      })
    );
  }
};


const deepFind = (obj = {}, propName = '', fn = (obj, prop, value) => null) => {

  if (typeof obj === "object" || obj !== null) {
    for (const prop in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (!obj.hasOwnProperty(prop)) { continue; }

      if (prop === propName) {
        fn(obj, prop, obj[prop]);
      }
      else if (typeof obj[prop] === 'object' && obj[prop] !== null) {
        deepFind(obj[prop], propName, fn);
      }
    }
  }
  return obj;
};



const docMeta = ((currentLang, locales) => {
  const that = {};
  const el_memo = {};

  that.getMetaTag = (metaProp) => {
    switch (metaProp) {
      case 'og:locale':
      case 'og:locale:alternate':
      case 'og:title':
      case 'og:description':
      case 'og:image': return that._getMetaTag('property', metaProp);
      case 'description': return that._getMetaTag('name', metaProp);
      default: return null;
    }
  };

  that.setMetaContent = (metaProp, content) => {
    const el_meta = that.getMetaTag(metaProp);
    if (el_meta && el_meta.nodeType === 1) {
      el_meta.setAttribute('content', content);
    }
    else {
      console.error('Meta property is not exists: ', metaProp);
    }
  };


  that._getMetaTag = (property, name) => {
    const sel = '[' + property + '="' + name + '"]';
    if (el_memo[sel]) { return el_memo[sel]; }
    const el = document.head.querySelector(sel);
    if (el && el.nodeType === 1) { el_memo[sel] = el; }
    else {
      console.log('ivalid meta tag', property, name);
    }
    return el;
  };

  that._removeElems = (selector) => {
    let elems = document.head.querySelectorAll(selector);
    if (elems && elems.length) {
      [...elems].forEach(el => el.parentNode && el.parentNode.removeChild(el));
    }
    elems = null;
  };

  that._addElemToHead = (tagName, attrs, toEl, wehre = 'after') => {
    const el = document.createElement(tagName);
    if (attrs.length) {
      for (let i = 0; i < attrs.length; i++) {
        const [attr, value] = attrs[i];
        el.setAttribute(attr, value);
      }
    }
    if (toEl && toEl.nodeType === 1) {
      toEl.parentNode.insertBefore(el, (
        wehre === 'after' ? toEl.nextSibling : toEl
      ));
    }
    else {
      document.head.prepend(el);
    }
  };

  that.setAlternate = (alternate) => {
    const langsCodes = Object.keys(alternate || {});

    that._removeElems(`link[rel="alternate"][hreflang]`);
    that._removeElems(`meta[property="og:locale:alternate"]`);

    for (let o = 0; o < langsCodes.length; o++) {
      const lang = langsCodes[o];
      const href = '/' + lang + '/' + alternate[lang];
      that._addElemToHead('link', [
        ['rel', 'alternate'],
        ['hreflang', lang],
        ['href', href]
      ]);

      if (lang !== currentLang) {
        const tld = locales[lang].tag;
        that._addElemToHead('meta', [
          ['property', 'og:locale:alternate'],
          ['content', tld]
        ], that._getMetaTag('property', 'og:locale'));
      }
    }
  };

  // <link rel="alternate" hreflang="en-gb" href="http://en-gb.example.com/page.html" />

  return {
    set(meta, options) {
      if (meta.title) {
        document.title = meta.title;
        that.setMetaContent('og:title', meta.title);
      }
      if (meta.desc) {
        // that.setMetaContent('og:description', meta.desc); // merged
        that.setMetaContent('description', meta.desc);
      }
      if (meta.image) {
        that.setMetaContent('og:image', meta.image);
      }
      if (meta.alternate && typeof meta.alternate === 'object') {
        that.setAlternate(meta.alternate, currentLang);
      }

      that.setMetaContent('og:locale', locales[currentLang].tag);

      document.documentElement.setAttribute('lang', currentLang);
    }
  };
})(window._i18n_lang, window._i18n_locales);


function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}


function isExternal(url) {
  // eslint-disable-next-line no-useless-escape
  var match = (url || "").match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
  if (typeof match[1] === "string" && match[1].length > 0 && match[1].toLowerCase() !== getGlobal().location.protocol) return true;
  if (typeof match[2] === "string" && match[2].length > 0 && match[2].replace(new RegExp(":(" + {"http:": 80, "https:": 443}[getGlobal().location.protocol] + ")?$"), "") !== getGlobal().location.host) return true;
  return false;
}



const getDeepProp = (obj, prop) => {
  const props = prop.split('.');
  let p;
  let found = obj;
  // date.dateformat
  while (props.length) {
    p = props.shift();
    found = found[p];
    if (props.length) {
      if (typeof found !== 'object' || found[p] === null) {
        return void 0;
      }
    }
    else {
      return found;
    }
  }
};

const setDeepProp = (obj, prop, value) => {
  const props = prop.split('.');
  let p;
  let found = obj;
  // date.dateformat
  while (props.length) {
    p = props.shift();
    if (props.length) {
      found = found[p];
    }
    else if (found && typeof found === "object") {
      found[p] = value;
    }
  }
  return obj;
};



function filterHtml(s) {
  // todo
  return s;
}


const debounce = (callback, delay = 250) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      timeoutId = null;
      callback(...args);
    }, delay);
  };
};

export {
  debounce,
  getDeepProp,
  setDeepProp,
  isNumeric,
  el_trigger,
  deepFind,
  docMeta,
  isExternal,
  pathNormalizer,
  filterHtml
};
export default {
  debounce,
  getDeepProp,
  setDeepProp,
  isNumeric,
  el_trigger,
  deepFind,
  docMeta,
  isExternal,
  pathNormalizer,
  filterHtml
};
